
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import { VueUploadItem } from "vue-upload-component";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import SliderEditor from "@/cloudfun/components/SliderEditor.vue";
import BrandService from "./Service.vue";

export default defineComponent({
  components: {
    Grid,
    FileUploader,
    SliderEditor,
    BrandService
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});
    const thumbnailUploader = ref<any>({});
    const serviceVisible = ref(false);
    const currentBrand = ref<any>({});

    const gridOptions: GridOptions = {
      title: "品牌資料",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Alias",
          title: "別名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "IsChoice",
          title: "精選",
          showHeaderOverflow: true,
          showOverflow: true,
          width: 100,
          align: "center",
          sortable: true,
          cellRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否", disabled: true }
          }
        },
        {
          field: "Filterable",
          title: "分類篩選",
          showHeaderOverflow: true,
          showOverflow: true,
          width: 100,
          align: "center",
          sortable: true,
          cellRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否", disabled: true }
          }
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "center",
          width: 100,
          sortable: true
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "center",
          width: 100,
          sortable: true,
          resizable: false,
          cellRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否", disabled: true }
          }
        },
        {
          field: "Id",
          title: "服務項目",
          showHeaderOverflow: true,
          showOverflow: true,
          width: 100,
          align: "center",
          slots: { default: "column-service" }
        }
      ],
      promises: {
        query: model
          ? params => model.dispatch("brand/query", params)
          : undefined,
        queryAll: model
          ? params => model.dispatch("brand/query", params)
          : undefined,
        save: model ? params => model.dispatch("brand/save", params) : undefined
      },
      modalConfig: { width: "95%", height: "95%", showFooter: true }
    };
    const formOptions = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 24,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } }
        },
        {
          field: "Alias",
          title: "別名",
          span: 24,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } }
        },
        {
          field: "WebSite",
          title: "品牌官方網站",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入完整連結 ex: https://xxx.com.tw" }
          }
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        },
        {
          field: "IsChoice",
          title: "精選",
          span: 12,
          itemRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否" }
          }
        },
        {
          field: "Filterable",
          title: "分類篩選",
          span: 12,
          itemRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否" }
          }
        },
        {
          field: "Published",
          title: "已發佈",
          span: 12,
          itemRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否" }
          }
        },
        {
          field: "Description",
          title: "品牌說明",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: { placeholder: "請輸入品牌說明" }
          }
        }
      ],
      rules: {
        Name: [{ required: true }],
        Alias: [{ required: true }]
      }
    };

    const editorOptions = {
      editor: ClassicEditor.Editor,
      config: {
        mediaEmbed: {
          previewsInData: true
        },
        extraPlugins: [UploadAdapterPlugin, "MediaEmbed"],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            "imageInsert",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown", "Title"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    };

    const pictureFormOptions = {
      titleWidth: 40,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 14,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        },
        {
          field: "Ordinal",
          title: "順序",
          span: 10,
          titleWidth: 40,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", max: 128 }]
      }
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`,
      editorOptions,
      sliderWidth: ref("100%"),
      pictureFormOptions,
      showAttribute: ref(true),
      showAlbum: ref(true),
      showDescription: ref(true),
      thumbnailUploader,
      serviceVisible,
      currentBrand
    };
  },
  methods: {
    onGridAdd(row: any, callback: any) {
      row.Published = false;
      row.IsChoice = false;
      row.Filterable = false;
      row.Ordinal = 0;
      row.Id = 0;
      row.Description = "";
      row.PictureAlbum = {
        Pictures: []
      };

      callback();
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("brand/find", row.Id)
        : undefined;

      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    onSliderEdit(image: any, callback: any) {
      image.AlbumId = this.grid.editingRow.PictureAlbumId;
      callback();
    },
    async onSliderRemove(image: any, callback: any) {
      await this.$model.dispatch("picture/delete", image.Id);
      callback();
    },
    async onSliderSave(params: any, callback: any) {
      await this.$model.dispatch("picture/save", params).then(
        () => {
          this.$model
            .dispatch("pictureAlbum/find", this.grid.editingRow.PictureAlbumId)
            .then(
              entity => {
                entity.Pictures.forEach((picture: any) => {
                  delete picture.$id;
                  delete picture.Album;
                });
                const sliderEditor: any = this.$refs.sliderEditor;
                sliderEditor.reload(
                  entity.Pictures.sort(
                    (a: any, b: any) => a.Ordinal - b.Ordinal
                  )
                );
              },
              reason => {
                CloudFun.send("error", {
                  subject: "重載失敗",
                  content: reason
                });
              }
            );
        },
        reason => {
          CloudFun.send("error", { subject: "保存失敗", content: reason });
        }
      );
      callback();
    },
    async editService(row: any) {
      console.log("id", row);
      this.currentBrand = row;
      this.serviceVisible = true;
    },
    async hideModal() {
      console.log("call hideModal");
      this.serviceVisible = false;
    }
  }
});
