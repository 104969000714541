
import CloudFun, {
  computed,
  Condition,
  defineComponent,
  Operator,
  ref,
  watch
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import { VxeTableConstructor } from "vxe-table";

export default defineComponent({
  components: {
    Grid,
    FileUploader
  },
  props: {
    modelValue: Boolean,
    data: {
      type: Object as any,
      required: true
    }
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});
    const currentData = ref<any>({});
    const title = computed(
      () =>
        `${props.data.Id && props.data.Id > 0 ? "修改" : "新增"}品牌服務項目`
    );

    watch(
      () => props.data,
      current => {
        currentData.value = current;
        grid.value.refresh();
      }
    );

    const gridOptions: GridOptions = {
      title: "品牌服務項目",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      columns: [
        {
          field: "Name",
          title: "標題",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Photo.Id",
          title: "圖片",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          slots: { default: "column-photo-id" }
        },
        {
          field: "Introduction",
          title: "介紹",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "center",
          width: 100,
          sortable: true
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "center",
          width: 100,
          sortable: true,
          resizable: false,
          cellRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否", disabled: true }
          }
        }
      ],
      promises: {
        query: model
          ? params => {
              if (params.condition) {
                console.log("currentData", currentData.value);
                params.condition.and(
                  "BrandId",
                  Operator.Equal,
                  currentData.value.Id
                );
              }
              return model.dispatch("brandService/query", params);
            }
          : undefined,
        queryAll: model
          ? params => model.dispatch("brandService/query", params)
          : undefined,
        save: model
          ? params => model.dispatch("brandService/save", params)
          : undefined
      },
      modalConfig: { showFooter: true, width: "650" }
    };
    const formOptions = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "標題",
          span: 24,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } }
        },
        {
          field: "Introduction",
          title: "說明",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: { placeholder: "請輸入說明" }
          }
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 24,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        },
        {
          field: "Published",
          title: "已發佈",
          span: 24,
          itemRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否" }
          }
        }
      ],
      rules: {
        Name: [{ required: true }]
      }
    };

    const closeModal = () => {
      emit("dismiss");
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`,
      showAttribute: ref(true),
      showAlbum: ref(true),
      showDescription: ref(true),
      title,
      closeModal,
      currentData
    };
  },
  methods: {
    onGridAdd(row: any, callback: any) {
      row.Published = false;
      row.Ordinal = 0;
      row.BrandId = this.currentData.Id;
      row.Photo = {
        Id: 0,
        Uri: ""
      };
      callback();
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("brandService/find", row.Id)
        : undefined;

      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  }
});
