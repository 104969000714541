<template>
  <div>
    <vxe-modal
      :model-value="modelValue"
      :title="title"
      width="80%"
      height="auto"
      @close="closeModal"
      @hide="closeModal"
    >
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">品牌服務項目</h2>
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div
              class="dropdown-box__content box dark:bg-dark-1 p-2 w-32"
            ></div>
          </div>
        </div>
        <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <!-- <div class="xl:flex sm:mr-auto">
            <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
              <input
                type="text"
                class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
                v-model.trim="grid.keyword"
                placeholder="請輸入關鍵字"
                @keyup.enter="grid.refresh"
              />
            </div>
            <div class="mt-2 xl:mt-0">
              <button
                type="button"
                class="button w-full sm:w-16 bg-theme-1 text-white"
                @click="grid.refresh"
              >
                搜尋
              </button>
            </div>
          </div>
          <div class="flex mt-2 sm:mt-0"></div> -->
        </div>
        <div class="overflow-x-auto scrollbar-hidden mt-2">
          <Grid
            ref="grid"
            v-bind="gridOptions"
            @addNewRow="onGridAdd"
            @edit="onGridEdit"
            @removeSelectedRows="onGridRemoveSelectedRows"
          >
            <!-- <template #operate="{ row }">
            <slot name="operate" :row="row">
              <div class="flex" v-if="!isEditing">
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-1 text-white"
                  title="編輯"
                  @click="edit(row)"
                >
                  <FontAwesome icon="highlighter" class="w-4 h-4" />
                </button>
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-6 text-white"
                  title="取消"
                  @click="remove(row)"
                >
                  <FontAwesome icon="trash" class="w-4 h-4" />
                </button>
              </div>
            </slot>
          </template> -->
            <template #column-photo-id="{row}">
              <div class="">
                <img :src="row.Photo?.Uri" class="h-16 p-2" />
              </div>
            </template>

            <template #modal="{ row, submit, reset }">
              <div class="flex items-center justify-center">
                <div
                  class="post intro-y overflow-hidden box mx-3 mb-5 border p-3"
                >
                  <div class="flex justify-center items-center ml-1 mb-3">
                    <a href="javascript:void(0)">圖片</a>
                  </div>
                  <div>
                    <FileUploader
                      ref="thumbnailUploader"
                      class="mx-5 mb-12"
                      id="brand-photo-thumbnail"
                      style="min-width: 278px; max-width: 278px; min-height: 156px; max-height: 156px;"
                      mode="image"
                      :modelValue="row.Photo?.Uri"
                      :action="uploadAction"
                      :autoUpload="true"
                      :headers="{
                        Authorization: 'Basic ' + $model.getCertificate(0)
                      }"
                      @update:modelValue="
                        value => {
                          if (row.Photo) row.Photo.Uri = value;
                          else row.Photo = { Uri: value };
                        }
                      "
                      @filter="uploaderFilter"
                    />
                  </div>
                </div>
              </div>
              <div class="flex items-center">
                <vxe-form
                  ref="form"
                  class="w-full"
                  v-bind="formOptions"
                  :data="row"
                  @submit="submit"
                  @reset="reset"
                >
                </vxe-form>
              </div>
            </template>
            <template #modal-footer>
              <vxe-button
                type="submit"
                status="primary"
                content="確認"
                @click="
                  $refs.form.validate(errMap => {
                    if (errMap === undefined)
                      $refs.form.dispatchEvent('submit');
                  })
                "
              ></vxe-button>
              <vxe-button
                type="reset"
                content="重置"
                @click="$refs.form.dispatchEvent('reset')"
              ></vxe-button>
            </template>
          </Grid>
        </div>
      </div>
      <!-- END: HTML Table Data -->
    </vxe-modal>
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}

.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script lang="ts">
import CloudFun, {
  computed,
  Condition,
  defineComponent,
  Operator,
  ref,
  watch
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import { VxeTableConstructor } from "vxe-table";

export default defineComponent({
  components: {
    Grid,
    FileUploader
  },
  props: {
    modelValue: Boolean,
    data: {
      type: Object as any,
      required: true
    }
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});
    const currentData = ref<any>({});
    const title = computed(
      () =>
        `${props.data.Id && props.data.Id > 0 ? "修改" : "新增"}品牌服務項目`
    );

    watch(
      () => props.data,
      current => {
        currentData.value = current;
        grid.value.refresh();
      }
    );

    const gridOptions: GridOptions = {
      title: "品牌服務項目",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      columns: [
        {
          field: "Name",
          title: "標題",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Photo.Id",
          title: "圖片",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          slots: { default: "column-photo-id" }
        },
        {
          field: "Introduction",
          title: "介紹",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "center",
          width: 100,
          sortable: true
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "center",
          width: 100,
          sortable: true,
          resizable: false,
          cellRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否", disabled: true }
          }
        }
      ],
      promises: {
        query: model
          ? params => {
              if (params.condition) {
                console.log("currentData", currentData.value);
                params.condition.and(
                  "BrandId",
                  Operator.Equal,
                  currentData.value.Id
                );
              }
              return model.dispatch("brandService/query", params);
            }
          : undefined,
        queryAll: model
          ? params => model.dispatch("brandService/query", params)
          : undefined,
        save: model
          ? params => model.dispatch("brandService/save", params)
          : undefined
      },
      modalConfig: { showFooter: true, width: "650" }
    };
    const formOptions = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "標題",
          span: 24,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } }
        },
        {
          field: "Introduction",
          title: "說明",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: { placeholder: "請輸入說明" }
          }
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 24,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        },
        {
          field: "Published",
          title: "已發佈",
          span: 24,
          itemRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否" }
          }
        }
      ],
      rules: {
        Name: [{ required: true }]
      }
    };

    const closeModal = () => {
      emit("dismiss");
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`,
      showAttribute: ref(true),
      showAlbum: ref(true),
      showDescription: ref(true),
      title,
      closeModal,
      currentData
    };
  },
  methods: {
    onGridAdd(row: any, callback: any) {
      row.Published = false;
      row.Ordinal = 0;
      row.BrandId = this.currentData.Id;
      row.Photo = {
        Id: 0,
        Uri: ""
      };
      callback();
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("brandService/find", row.Id)
        : undefined;

      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  }
});
</script>
