<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">品牌管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div
              class="dropdown-box__content box dark:bg-dark-1 p-2 w-32"
            ></div>
          </div>
        </div>
        <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0"></div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <Grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="onGridAdd"
          @edit="onGridEdit"
          @removeSelectedRows="onGridRemoveSelectedRows"
        >
          <template #column-service="{ row }">
            <slot name="operate" :row="row">
              <div class="flex justify-center">
                <button
                  class="button rounded-full bg-theme-1 text-white"
                  title="管理"
                  @click="editService(row)"
                >
                  <FontAwesome icon="highlighter" class="w-4 h-4" />
                </button>
              </div>
            </slot>
          </template>

          <template #modal="{ row, submit, reset }">
            <div class="flex items-center">
              <vxe-form
                ref="form"
                class="w-full"
                v-bind="formOptions"
                :data="row"
                @submit="submit"
                @reset="reset"
              >
              </vxe-form>
            </div>

            <div class="flex items-center justify-center">
              <div
                class="post intro-y overflow-hidden box mx-3 mb-5 border p-3"
              >
                <div class="flex justify-center items-center ml-1 mb-3">
                  <a href="javascript:void(0)">品牌縮圖</a>
                </div>
                <div>
                  <FileUploader
                    ref="thumbnailUploader"
                    class="mx-5 mb-12"
                    id="brand-photo-thumbnail"
                    style="min-width: 278px; max-width: 278px; min-height: 156px; max-height: 156px;"
                    mode="image"
                    :modelValue="row.Photo?.ThumbnailUri"
                    :action="uploadAction"
                    :autoUpload="true"
                    :headers="{
                      Authorization: 'Basic ' + $model.getCertificate(0)
                    }"
                    @update:modelValue="
                      value => {
                        if (row.Photo) row.Photo.ThumbnailUri = value;
                        else row.Photo = { ThumbnailUri: value };
                      }
                    "
                    @filter="uploaderFilter"
                  />
                </div>
              </div>

              <div
                class="post intro-y overflow-hidden box mx-3 mb-5 border p-3"
              >
                <div class="flex justify-center items-center ml-1 mb-3">
                  <a href="javascript:void(0)">品牌 Banner</a>
                </div>
                <div>
                  <FileUploader
                    ref="uploader"
                    class="mx-5 mb-12"
                    id="brand-photo-uri"
                    style="min-width: 500px; max-width: 500px; min-height: 156px; max-height: 156px;"
                    mode="image"
                    :modelValue="row.Photo?.Uri"
                    :action="uploadAction"
                    :autoUpload="true"
                    :headers="{
                      Authorization: 'Basic ' + $model.getCertificate(0)
                    }"
                    @update:modelValue="
                      value => {
                        if (row.Photo) row.Photo.Uri = value;
                        else row.Photo = { Uri: value };
                      }
                    "
                    @filter="uploaderFilter"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="row && row.PictureAlbumId > 0"
              class="post intro-y overflow-hidden box mx-3 mt-2 border p-3"
            >
              <div class="flex items-center ml-1 mb-3">
                <a href="javascript:void(0)" @click="showAlbum = !showAlbum"
                  >圖集</a
                >
                <a href="javascript:void(0)" @click="showAlbum = !showAlbum"
                  ><FontAwesome
                    class="ml-2 h-4"
                    :icon="showAlbum ? 'angle-up' : 'angle-down'"
                /></a>
              </div>
              <div v-show="showAlbum">
                <SliderEditor
                  ref="sliderEditor"
                  :navigation="true"
                  :width="sliderWidth"
                  :filePostAction="uploadAction"
                  :formOptions="pictureFormOptions"
                  v-model="row.PictureAlbum.Pictures"
                  @add="onSliderEdit"
                  @edit="onSliderEdit"
                  @save="onSliderSave"
                  @remove="onSliderRemove"
                />
              </div>
            </div>
          </template>
          <template #modal-footer>
            <vxe-button
              type="submit"
              status="primary"
              content="確認"
              @click="
                $refs.form.validate(errMap => {
                  if (errMap === undefined) $refs.form.dispatchEvent('submit');
                })
              "
            ></vxe-button>
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template>
        </Grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <BrandService
      v-model="serviceVisible"
      :data="currentBrand"
      @dismiss="hideModal"
    ></BrandService>
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}

.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script lang="ts">
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import { VueUploadItem } from "vue-upload-component";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import SliderEditor from "@/cloudfun/components/SliderEditor.vue";
import BrandService from "./Service.vue";

export default defineComponent({
  components: {
    Grid,
    FileUploader,
    SliderEditor,
    BrandService
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});
    const thumbnailUploader = ref<any>({});
    const serviceVisible = ref(false);
    const currentBrand = ref<any>({});

    const gridOptions: GridOptions = {
      title: "品牌資料",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Alias",
          title: "別名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "IsChoice",
          title: "精選",
          showHeaderOverflow: true,
          showOverflow: true,
          width: 100,
          align: "center",
          sortable: true,
          cellRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否", disabled: true }
          }
        },
        {
          field: "Filterable",
          title: "分類篩選",
          showHeaderOverflow: true,
          showOverflow: true,
          width: 100,
          align: "center",
          sortable: true,
          cellRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否", disabled: true }
          }
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "center",
          width: 100,
          sortable: true
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "center",
          width: 100,
          sortable: true,
          resizable: false,
          cellRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否", disabled: true }
          }
        },
        {
          field: "Id",
          title: "服務項目",
          showHeaderOverflow: true,
          showOverflow: true,
          width: 100,
          align: "center",
          slots: { default: "column-service" }
        }
      ],
      promises: {
        query: model
          ? params => model.dispatch("brand/query", params)
          : undefined,
        queryAll: model
          ? params => model.dispatch("brand/query", params)
          : undefined,
        save: model ? params => model.dispatch("brand/save", params) : undefined
      },
      modalConfig: { width: "95%", height: "95%", showFooter: true }
    };
    const formOptions = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 24,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } }
        },
        {
          field: "Alias",
          title: "別名",
          span: 24,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } }
        },
        {
          field: "WebSite",
          title: "品牌官方網站",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入完整連結 ex: https://xxx.com.tw" }
          }
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        },
        {
          field: "IsChoice",
          title: "精選",
          span: 12,
          itemRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否" }
          }
        },
        {
          field: "Filterable",
          title: "分類篩選",
          span: 12,
          itemRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否" }
          }
        },
        {
          field: "Published",
          title: "已發佈",
          span: 12,
          itemRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否" }
          }
        },
        {
          field: "Description",
          title: "品牌說明",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: { placeholder: "請輸入品牌說明" }
          }
        }
      ],
      rules: {
        Name: [{ required: true }],
        Alias: [{ required: true }]
      }
    };

    const editorOptions = {
      editor: ClassicEditor.Editor,
      config: {
        mediaEmbed: {
          previewsInData: true
        },
        extraPlugins: [UploadAdapterPlugin, "MediaEmbed"],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            "imageInsert",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown", "Title"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    };

    const pictureFormOptions = {
      titleWidth: 40,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 14,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        },
        {
          field: "Ordinal",
          title: "順序",
          span: 10,
          titleWidth: 40,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", max: 128 }]
      }
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`,
      editorOptions,
      sliderWidth: ref("100%"),
      pictureFormOptions,
      showAttribute: ref(true),
      showAlbum: ref(true),
      showDescription: ref(true),
      thumbnailUploader,
      serviceVisible,
      currentBrand
    };
  },
  methods: {
    onGridAdd(row: any, callback: any) {
      row.Published = false;
      row.IsChoice = false;
      row.Filterable = false;
      row.Ordinal = 0;
      row.Id = 0;
      row.Description = "";
      row.PictureAlbum = {
        Pictures: []
      };

      callback();
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("brand/find", row.Id)
        : undefined;

      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    onSliderEdit(image: any, callback: any) {
      image.AlbumId = this.grid.editingRow.PictureAlbumId;
      callback();
    },
    async onSliderRemove(image: any, callback: any) {
      await this.$model.dispatch("picture/delete", image.Id);
      callback();
    },
    async onSliderSave(params: any, callback: any) {
      await this.$model.dispatch("picture/save", params).then(
        () => {
          this.$model
            .dispatch("pictureAlbum/find", this.grid.editingRow.PictureAlbumId)
            .then(
              entity => {
                entity.Pictures.forEach((picture: any) => {
                  delete picture.$id;
                  delete picture.Album;
                });
                const sliderEditor: any = this.$refs.sliderEditor;
                sliderEditor.reload(
                  entity.Pictures.sort(
                    (a: any, b: any) => a.Ordinal - b.Ordinal
                  )
                );
              },
              reason => {
                CloudFun.send("error", {
                  subject: "重載失敗",
                  content: reason
                });
              }
            );
        },
        reason => {
          CloudFun.send("error", { subject: "保存失敗", content: reason });
        }
      );
      callback();
    },
    async editService(row: any) {
      console.log("id", row);
      this.currentBrand = row;
      this.serviceVisible = true;
    },
    async hideModal() {
      console.log("call hideModal");
      this.serviceVisible = false;
    }
  }
});
</script>
